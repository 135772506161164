<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },
        computed: {
            ...mapState('auth', ['user']),
            isAdmin() {
                return this.user.admin;
            },
            list() {
                return [
                    {
                        title: 'Карточка',
                        url: `/sites/${this.$route.params.site_id}/products/${this.$route.params.id}/detail`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Редактировать',
                        url: `/sites/${this.$route.params.site_id}/products/${this.$route.params.id}/edit`
                    },
                    {
                        title: 'Опции',
                        url: `/sites/${this.$route.params.site_id}/products/${this.$route.params.id}/options`,
                        disabled: !this.$route.params.id
                    },
                ]
            }
        },
        mounted() {
            // if(this.isAdmin) {
            //     this.list = [
            //         {
            //             title: 'Карточка',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}`
            //         },
            //         {
            //             title: 'Редактировать',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/edit`
            //         },
            //         {
            //             title: 'Описание',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/description`
            //         },
            //         {
            //             title: 'Категории',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/categories`
            //         },
            //         {
            //             title: 'Блюда',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/products`
            //         },
            //         {
            //             title: 'Опции',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/options`
            //         },
            //         {
            //             title: 'Варианты',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/variants`
            //         },
            //         {
            //             title: 'Импорт',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/import`
            //         },
            //         {
            //             title: 'Акции',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/promotions`
            //         },
            //         {
            //             title: 'Дополнительно',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/additional`
            //         },
            //         {
            //             title: 'Словарь',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/dictionary`
            //         },
            //         {
            //             title: 'Доставка',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/delivery`
            //         },
            //         {
            //             title: 'Перенаправление доставки',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/delivery-address-redirect`
            //         },
            //         {
            //             title: 'Настройки',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/settings`
            //         },
            //     ]
            // } else {
            //     this.list = [
            //         {
            //             title: 'Карточка',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}`
            //         },
            //         {
            //             title: 'Описание',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/description`
            //         },
            //         {
            //             title: 'Категории',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/categories`
            //         },
            //         {
            //             title: 'Блюда',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/products`
            //         },
            //         {
            //             title: 'Опции',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/options`
            //         },
            //         {
            //             title: 'Варианты',
            //             url: `/entities/${this.$route.params.code}/${this.$route.params.id}/variants`
            //         },
            //     ]
            // }
        },
    }
</script>
