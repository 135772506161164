<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ entity.title }}</a>&nbsp;-&nbsp;{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />

                    
                    <template v-for="group in groups">
                        <v-card class="pa-4 mb-4" :key="`group-${ group.id }`">
                            <div class="options-group-title">
                                <span :class="`true-false-label grey`">
                                    &nbsp;{{group.multiple ? 'Мульти выбор' : 'Выбор одного' }}&nbsp;
                                </span>
                                <span :class="`true-false-label cursor-pointer ${getBadge(group.enabled)}`" @click="edit(group)">
                                    {{group.title}} <v-icon color="white">mdi-pencil</v-icon>
                                </span>
                                <v-btn color="primary" class="ml-3" @click="optionCreate(group)">
                                    <v-icon>mdi-plus</v-icon> Добавить опцию
                                </v-btn>
                            </div>
                            <v-card-text v-if="group.options && group.options.length">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <td>Позиция</td>
                                                <td>Название</td>
                                                <td>Цена</td>
                                                <td>Вес</td>
                                                <td>Активен</td>
                                                <td></td>
                                                <!-- <td></td> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in group.options" :key="`option-${item.id}`">
                                                <td>{{ item.position }}</td>
                                                <td>{{ item.title }}</td>
                                                <td>{{ priceFormated(item.price) }}</td>
                                                <td>{{ weightFormated(item.weight) }}</td>
                                                <td>
                                                    <span :class="`true-false-label ${getBadge(item.enabled)}`">
                                                        &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
                                                    </span>
                                                </td>
                                                <td align="right">
                                                    <v-icon color="primary" @click="optionEdit(item)">mdi-pencil</v-icon>
                                                </td>
                                                <!-- <td>
                                                    <v-icon color="primary" @click="remove(item)">mdi-trash-can</v-icon>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить группу
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack">Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Группа
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editGroup.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editGroup.position"
                                    outlined
                                    label="Позиция"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editGroup.multiple"
                                    label="Мульти выбор"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editGroup.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editGroup.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="editOptionModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Опция
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editOption.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :value="editOption.formatedPrice"
                                    @input="setPrice"
                                    outlined
                                    label="Цена"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editOption.weight"
                                    outlined
                                    label="Вес (в граммах)"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editOption.position"
                                    outlined
                                    label="Позиция"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editOption.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="optionSave"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editOptionModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editOption.id"
                        color="error"
                        class="ml-3"
                        @click="optionRemove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';
    import ProductTabLinks from '../../components/sites/ProductTabLinks';

    import { priceFormated, weightFormated } from '../../helpers';

    export default {
        name: 'ProductOptions',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                priceFormated,
                weightFormated,
                selectedOption: null,
                editModal: false,
                editOptionModal: false,
                editGroup: {},
                editOption: {}
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('products', {
                product: state => state.entity
            }),
            ...mapState('groups', {
                groups: state => state.entities
            }),
            ...mapState('options', {
                options: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            }
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            await store.dispatch('siteTypes/fetch');
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
                await store.dispatch('options/fetch', { site_id });
                this.product.site_id = site_id;
            }
            if(site_id && id) {
                await store.dispatch('products/get', { site_id, id });
                await store.dispatch('groups/fetch', { site_id, product_id: id });
            }
        },

        methods: {
            create() {
                this.editGroup = {
                    site_id: this.$route.params.site_id,
                    product_id: this.$route.params.id,
                    title: 'На выбор',
                    multiple: false,
                    enabled: true,
                    position: (this.groups.length + 1) * 10
                };
                this.editModal = true;
            },
            edit(item) {
                this.editGroup = item;
                this.editModal = true;
            },
            async save() {
                try {
                    const { site_id, id } = this.$route.params;
                    store.commit('groups/SET_ENTITY', this.editGroup);
                    await store.dispatch('groups/save', {site_id, product_id: id});
                    await store.dispatch('groups/fetch', {site_id, product_id: id});
                    this.editModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                try {
                    const { site_id, id } = this.$route.params;
                    await store.dispatch('groups/delete', {site_id, product_id: id, id: this.editGroup.id});
                    await store.dispatch('groups/fetch', {site_id, product_id: id});
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },

            setPrice(price) {
                this.editOption.formatedPrice = price;
                this.editOption.price = price * 100; 
            },
            optionCreate(group) {
                this.editOption = {
                    site_id: this.$route.params.site_id,
                    group_id: group.id,
                    enabled: true,
                    position: (group.options.length + 1) * 10
                };
                this.editOptionModal = true;
            },
            optionEdit(item) {
                this.editOption = item;
                this.editOption.formatedPrice = item.price / 100;
                this.editOptionModal = true;
            },
            async optionSave() {
                try {
                    const { site_id, id } = this.$route.params;
                    store.commit('options/SET_ENTITY', this.editOption);
                    await store.dispatch('options/save', { site_id, product_id: id, group_id: this.editOption.group_id });
                    await store.dispatch('groups/fetch', {site_id, product_id: id});
                    this.editOptionModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async optionRemove() {
                try {
                    const { site_id, id } = this.$route.params;
                    await store.dispatch('options/delete', {site_id, product_id: id, group_id: this.editOption.group_id, id: this.editOption.id});
                    await store.dispatch('groups/fetch', {site_id, product_id: id});
                    this.editOptionModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },

            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/products`);
                } else {
                    this.$router.push('/sites');
                }
            },
            goBack() {
                const { site_id, id } = this.$route.params;
                if(site_id && id) {
                    this.$router.push(`/sites/${site_id}/products/${id}/detail`);
                } else if(site_id) {
                    this.$router.push(`/sites/${site_id}/products`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>

<style lang="scss">
.options-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}
</style>